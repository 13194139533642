import React, { useState, useEffect } from "react"
import { Link, graphql, navigate } from "gatsby"
import styled from 'styled-components';
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Text from "../components/text"
import PortableText from "@sanity/block-content-to-react"
import serializer from "../utils/serializer"
import { 
  breakpoint,
  margin,
  headerHeight,
  footerHeightMobile,
  type_body,
  type_body_bold,
  baseColor, 
  greyColor, 
  blackColor, 
  grid, 
  colorTransition
} from '../styles/helpers'
import { 
  PageWrap, 
  PageWrapInner 
} from '../styles/page'


const SituLabel = styled.div`
  ${type_body};
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border: solid black 1px;
  margin-top: -${headerHeight};
  text-align: center;
  & h1 {
    ${type_body_bold};
  }
  & h2 {
    ${type_body};
    font-weight: normal;
  }
  @media ${breakpoint.xs} {
    margin-top: -${footerHeightMobile};
  }
  @media ${breakpoint.xxl} {
    width: 300px;
    height: 150px;
  }
`

const InSituPage = ({ data, location, ...props }) => {
  return (
    <>
    <SEO title="In Situ" />
    <PageWrap fixed={true}>
      <PageWrapInner centerItems={true} columnWidth={8}>
        <SituLabel>
          <h1>In Situ</h1>
          <h2>TBA</h2> 
        </SituLabel>
      </PageWrapInner>
    </PageWrap>
    </>
  )
}

export default InSituPage
